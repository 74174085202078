import {
    Stack,
    Text,
    Box,
    Image,
    Center,
    Heading,
    useColorModeValue,
    Link,
    OrderedList,
    ListItem,
    UnorderedList,
    Container
} from '@chakra-ui/react';

const Feedback = ({ color }) => {
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <>
            <Container maxW='3xl' pt='124px'>
                {/* <Flex pt='124px'> */}
                <Stack maxW={['sm', 'md', '2xl']} mx='auto' spacing={5} color={textColor} fontSize={'lg'}>
                    <Heading fontWeight={'400'} textAlign={'center'} size='lg'>Feedback Guidelines</Heading>

                    {/* <Heading fontWeight={'500'} textAlign={'center'} size='sm'>Defining roles and responsibilities for various levels</Heading> */}
                    <Text textAlign={'center'} >Methods and processes for effective feedback</Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/feedback/feedback_3.jpg' />
                        <Text fontSize={'md'}>Image courtesy: Lunarbaboon</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>The problem</Heading >

                    <Text>When I first joined <Text as={'span'} color={`${color}.400`}>inFeedo</Text>, I noticed that there was a lot of chaos and time wasted due to miscommunication and the resulting rework. The problem was further exacerbated by the fact that our team was fully remote, designers were working in silos across different Product pods, and there was insufficient communication happening. I realised that the need of the hour was to set up a robust feedback process for the design team. This would involve setting up defined guidelines for the team to follow to reduce misunderstandings, enable early feedback and iterations, minimize rework, and increase efficiency. Inspired by <Link href='https://www.figma.com/blog/design-critiques-at-figma/'>Noah Levin's article</Link> on Design Critiques at Figma, I decided to set up our own Feedback Guidelines that would be relevant for our remote team.</Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Scheduled feedback sessions</Heading >

                    <Text>I set up 2 recurring sessions for the design team. </Text>

                    <OrderedList>
                        <ListItem><Text as={'span'} color={`${color}.400`}>Monday Sync-up:</Text> This would be a quick meeting where every designer had to share 3 key points: (1) What they did last week (2) What they will do this week (3) Blockers and dependencies. The main idea behind this was to make each team member aware of what was happening in the rest of the team and what their peers were working on. This fostered more intra-team collaboration and reduced rework. I noticed that they had also started actively volunteering to help their peers when they were stuck.</ListItem>
                        <Box h={5}></Box>
                        <ListItem><Text as={'span'} color={`${color}.400`}>Thursday Peer Reviews:</Text> This was a longer meeting where designers had to volunteer to present. The agenda could inlude requesting feedback, quick FYIs, sharing design system component updates, or even sharing useful learning with the team (for instance, talk about a video you saw, or a book or article you read).</ListItem>
                    </OrderedList>

                    <Box h={5}></Box>

                    <Heading size='md'>The critique toolbelt</Heading >

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/feedback/feedback_1.webp' />
                        <Text fontSize={'md'}>Image courtesy: Figma</Text>
                    </Center>

                    <Box h={5}></Box>

                    <OrderedList>
                        <ListItem><b>Standard critique</b>
                            <Text>This is what we will primarily employ in our Thursday Peer Review meetings.</Text></ListItem>
                        <Box h={5}></Box>
                        <ListItem><b>Jam / Workshop</b>
                            <Text>We can employ this for larger upcoming projects. Only suitable when we are doing it well in advance of execution dates (ideally a quarter), and don’t have the pressure of deadlines.</Text></ListItem>
                        <Box h={5}></Box>
                        <ListItem><b>Pair Design</b>
                            <Text>I would suggest this mainly for quick fixes over a Slack huddle or Zoom call, or even larger design problems where a quick resolution is required.</Text></ListItem>
                        <Box h={5}></Box>
                        <ListItem><b>Silent critique</b>
                            <Text>We can use this mainly for <Text as={'span'} color={`${color}.400`}>async feedback</Text> on design documentation, Figma components or for times when you need early feedback (and not urgently) where we put the link out on our Slack group and ask people to add their comments. If feedback suggests that significant change or more discussion is required then this can lead to type 1 or 3.</Text></ListItem>
                        <Box h={5}></Box>
                        <ListItem><b>Paper Print-out</b>
                            <Text>Irrelevant for remote teams. Use type 1 or 4 instead</Text></ListItem>
                        <Box h={5}></Box>
                        <ListItem><b>FYI</b>
                            <Text>This is what our Monday Sync meeting is for. Mainly to provide a heads-up to people. This could lead to further discussions, but, after the meeting.</Text></ListItem>

                    </OrderedList>

                    <Box h={5}></Box>
                    <Heading size='md'>Guidelines</Heading >
                    <UnorderedList>
                        <ListItem>Explicitly state what kind of feedback you need: Be clear up-front whether you’re looking for feedback on the flow or content or visuals.</ListItem>
                        <ListItem>Be kind and empathetic: We’re in this together! Remember to not be rude or condescending when giving feedback.</ListItem>
                        <ListItem>Keep egos out: Remember we’re all working towards improving the product. Assume best intentions. Don’t get offended if someone points out a mistake or corrects you. Acknowledge with grace.</ListItem>
                        <ListItem>Ask probing questions: Don’t hold back. Ask as many questions as you need to clarify.</ListItem>
                        <ListItem>Be specific: Try not to be vague. State clearly what’s working and what’s not working.</ListItem>
                        <ListItem>Always advocate for the user but keep business objectives in mind.</ListItem>
                        <ListItem>Provide next steps: Diagnose the problems and suggest possible directions or solutions to explore.</ListItem>
                        <ListItem>If something does not fall into the scope of the session set by the team, feel free to point it out, note it down, and make sure to address it in future updates.</ListItem>
                    </UnorderedList>

                    <Box h={5}></Box>
                    <Center>
                        <Heading size='sm'>THE END</Heading>
                    </Center>
                    <Box h={10}></Box>

                </Stack>
                {/* </Flex> */}
            </Container>
        </>
    );
}
export default Feedback;