import { extendTheme } from "@chakra-ui/react";

//Adding color mode
const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const fonts = {
    fonts: {
        heading: `'Montserrat Variable', sans-serif`,
        body: `'Lato', sans-serif`,
    },
}

// Extending the theme
const theme = extendTheme(config, fonts);

export default theme;