import {
    Container,
    Stack,
    Box,
    HStack,
    Text,
    Divider,
    Heading,
    Center,
    Button,
    Link
} from '@chakra-ui/react'
import ResumeCard from './ResumeCard';

const Resume = ({ color }) => {
    return (
        <>
            <Container maxW={"4xl"} id="career">
                <Stack
                    as={Box}
                    textAlign={"center"}
                    spacing={{ base: 8, md: 14 }}
                    pb={{ base: 20, md: 36 }}
                >
                    <Stack align="left" direction="column" px={0}>
                        <HStack mx={4}>
                            <Text color={`${color}.400`} fontWeight={900}>
                                03
                            </Text>
                            <Text fontWeight={900} textTransform={'uppercase'}>Career</Text>
                        </HStack>
                        <Divider orientation="horizontal" />
                    </Stack>

                    <Center>
                        <Link href='/doc/Ashrith_Resume_2023.pdf' download>
                            <Button colorScheme={color} color={`${color}.400`} borderColor={`${color}.400`} variant={'outline'}> Download Resume</Button>
                        </Link>
                    </Center>
                    <Stack mx={['0', '0', '16']}>
                        <Heading size={'sm'} align={'left'} mx={4} pb={4}> Education </Heading>
                        <Stack mx={4} spacing={4}>
                            {EDU_DATA.map((item) => (
                                <ResumeCard key={item.company} item={item} />
                            ))}
                        </Stack>
                    </Stack>

                    <Stack mx={['0', '0', '16']}>
                        <Heading size={'sm'} align={'left'} mx={4} pb={4}> Work Experience </Heading>
                        <Stack mx={4} spacing={4}>
                            {RESUME_DATA.map((item) => (
                                <ResumeCard key={item.company} item={item} />
                            ))}
                        </Stack>
                    </Stack>

                </Stack>
            </Container>
        </>
    );
}
export default Resume;

const EDU_DATA = [
    {
        avatar: 'companies/umd.png',
        title: 'MS, Human-Computer Interaction',
        company: 'University of Maryland, USA',
        details: '',
    },
    {
        avatar: 'companies/pes.png',
        title: 'BE, Computer Science',
        company: 'PES University',
        details: '',
    },
];

const RESUME_DATA = [
    {
        avatar: 'companies/infeedo.png',
        title: 'Associate Director, UX',
        company: 'inFeedo',
        details: '',
    },
    {
        avatar: 'companies/scripbox.png',
        title: 'Lead Designer',
        company: 'Scripbox',
        details: ''
    },
    {
        avatar: 'companies/nobroker.png',
        title: 'Sr. UX Designer',
        company: 'NoBroker',
        details: ''
    },
    {
        avatar: 'companies/zeta.png',
        title: 'Interaction Designer',
        company: 'Zeta',
        details: ''
    },
    {
        avatar: 'companies/tapplent.png',
        title: 'Specialist - UX Design and Product Strategy',
        company: 'Tapplent',
        details: ''
    },
    {
        avatar: 'companies/borqs.png',
        title: 'Senior Engineer - UI',
        company: 'Borqs',
        details: ''
    },
    {
        avatar: 'companies/hp.png',
        title: 'Systems Software Engineer',
        company: 'Hewlett-Packard Enterprise',
        details: ''
    },
];