import {
    Stack,
    Image,
    Center,
    Box,
    Text,
    Heading,
    useColorModeValue,
    OrderedList,
    ListItem,
    Container,
    UnorderedList
} from '@chakra-ui/react';

const Audit = ({ color }) => {
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <>
            <Container maxW={"3xl"} pt='124px'>
                <Stack maxW={['sm', 'md', '2xl']} mx='auto' spacing={5} color={textColor} fontSize={'lg'}>

                    <Heading fontWeight={'400'} textAlign={'center'} size='lg'>Portfolio Audit</Heading>

                    <Text textAlign={'center'}>A second opinion for your mutual fund investments</Text>

                    {/* <Box h={5}></Box> */}

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_12.webp' alt="Overview image for feature" />
                        {/* <Text fontSize={'md'}>Hardware prototype of the TUI tool along with the generated output</Text> */}
                    </Center>

                    {/* <Box h={5}></Box> */}

                    <Heading size='md'>Overview</Heading>

                    <Text>
                        Portfolio Audit is a major feature that I worked on during my time at <Text as={'span'} color={`${color}.400`}>Scripbox</Text>. The purpose of this feature is to provide a comprehensive "second opinion" about the user's mutual fund portfolio, both within and outside of Scripbox.
                    </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>The Problem</Heading>

                    <Text>As part of the Share of Wallet product pod, the Product Manager, Jithu Gopal, and I were brainstorming about the various problems Scripbox faced when it came to increasing our share of wallet with our current customers. We set up calls with customers who also had mutual investments outside of Scripbox in order to better understand their needs and concerns.</Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Insights from Customer Interviews</Heading>

                    <UnorderedList>
                        <ListItem>Mutual fund choices are often purely based on recommendations from family or friends and with no additional research or analysis.</ListItem>
                        <ListItem>Fear of Missing Out (FOMO) is a major factor in decision-making</ListItem>
                        <ListItem>Sunk cost fallacy is a major factor in decision-making</ListItem>
                        <ListItem>They often get overwhelmed with financial jargon when trying to do their own research or talking to experts</ListItem>
                        <ListItem>Whenever they get financial advice the WHYs are hardly ever explained.</ListItem>
                        <ListItem>Many view advice cynically - "Perhaps you're recommending this only because your commissions will increase"</ListItem>
                    </UnorderedList>



                    <Text>Based on these customer insights, we hoped to achieve the following objectives with this feature:</Text>

                    <OrderedList>
                        <ListItem>Encourage users to add details of their external investments</ListItem>
                        <ListItem>Provide a holistic view of the health of a user's portfolio</ListItem>
                        <ListItem>Showcase and establish our financial expertise</ListItem>
                        <ListItem>Build trust with our customers</ListItem>
                        <ListItem>And, last but not the least, encourage them to move their investments to Scripbox</ListItem>
                    </OrderedList>

                    <Box h={5}></Box>

                    <Heading size='md'>Competitor Analysis</Heading>

                    <Text>
                        I created accounts across many of our competitor apps in order to analyse and compare their flows and their approach to showing users' an overview of their portfolio. This ranged from ones where there was hardly any information provided to those that overwhelmed the user with a lot of information and jargon.
                    </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_4.webp' alt="Comparing competitor flows and approach" />
                        <Text fontSize={'md'}>Comparing competitor flows and approach</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>Portfolio Audit Components</Heading>

                    <Text>Our final Portfolio Audit report consists of 3 parts: Summary of holdings, Portfolio Health, and Recommended Actions.</Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_10.webp' alt="Audit screens" />
                        <Text fontSize={'md'}>Three parts of Portfolio Audit: Summary, Health, and Recommendations</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>Portfolio Health criteria</Heading>

                    <Text>Based on inputs from our Investment Research team, their core criteria for evaluating mutual funds are as follows: </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_2.webp' alt="Analysis criteria for Equity funds" />
                        <Text fontSize={'md'}>Criteria for Equity funds</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_3.webp' alt="Analysis criteria for Debt funds" />
                        <Text fontSize={'md'}>Criteria for Debt funds</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Text>Using the above as a starting point, we came up a final list of 7 Portfolio Health criteria keeping in mind the need for even the most novice users to understand them. They are as follows:</Text>

                    <OrderedList>
                        <b>
                            <ListItem>Fund Quality: <Text>Our assessment of your fund quality based on our internal rating system driven by a proprietary algorithm.</Text></ListItem>
                            <Box h={3}></Box>
                            <ListItem>Concentration Risk: <Text>Fund holdings that the user is over-concentrated in.</Text></ListItem>
                            <Box h={3}></Box>
                            <ListItem>Tidiness: <Text>Fund holdings with small amounts that do not contribute meaningfully to the portfolio. We trim such positions to keep the portfolio organised.</Text></ListItem>
                            <Box h={3}></Box>
                            <ListItem>Category Attractiveness: <Text>Equity funds are in categories where we don't have a favourable outlook.</Text></ListItem>
                            <Box h={3}></Box>
                            <ListItem>Credit Quality: <Text>The credit quality of the underlying instruments present in the fund don't meet our quality standards.</Text></ListItem>
                            <Box h={3}></Box>
                            <ListItem>Interest Rate Sensitivity: <Text>Relative interest rate risk of the fund sub category is high. We prefer funds that are not affected by interest rate changes.</Text></ListItem>
                            <Box h={3}></Box>
                            <ListItem>Optimisation Potential: <Text>Fund positions that can be improved by moving to our recommended basket in a phased manner. These funds are not inherently bad but we believe this money can be managed better by keeping the right balance of performance and risk.</Text></ListItem>
                        </b>
                    </OrderedList>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_11.webp' alt="Portfolio health" />
                        <Text fontSize={'md'}>Portfolio Health</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>Portfolio Health details</Heading>

                    <Text>For the individual health criteria, we had the ensure that the detail page had enough information to convey the exact reason that a fund had to be replaced but also make sure that we do not overwhelm the users with excessive details. For this purpose, we had to make use of progressive disclosure and split the information into collapsible sections and auxiliary screens. That way those who just wanted information at a glance could get what they needed up-front but the more knowledgeable users could click through to view deeper analysis.</Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_5.webp' alt="Fund details auxiliary screens" />
                        <Text fontSize={'md'}>Diving deeper into a Portfolio Health criterion</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>Identifying and handling states</Heading>

                    <Text>We identified 4 primary states for portfolio, along with 5 combination states which are a combination of 2 or more of the primary states</Text>

                    <Box h={5}></Box>

                    <Heading size='sm'>PRIMARY STATES</Heading>

                    <UnorderedList>
                        <ListItem><b>Action Required:</b> Portfolio contains poor quality mutual funds that user needs to redirect their funds from.</ListItem>
                        <ListItem><b>No Action Required:</b> Healthy portfolio that requires no changes.</ListItem>
                        <ListItem><b>Monitored Funds:</b> No immediate action required but certain funds are being monitored by our team and might require action later.</ListItem>
                        <ListItem><b>No Analysis Available:</b> This means that either there is no applicable fund for the particular analysis criteria or that the Scripbox investment research team has not analysed and rated the fund yet.</ListItem>
                    </UnorderedList>

                    <Box h={5}></Box>

                    <Heading size='sm'>COMBINATION STATES</Heading>

                    <UnorderedList>
                        <ListItem><b>Action Required + Monitored States</b></ListItem>
                        <ListItem><b>Action Required + No Anaylsis (Unrated Funds)</b></ListItem>
                        <ListItem><b>No Action Required + No Anaylsis (Unrated Funds)</b></ListItem>
                        <ListItem><b>Monitored Funds + No Anaylsis (Unrated Funds)</b></ListItem>
                    </UnorderedList>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_9.webp' alt="Handling all the possible states" />
                        <Text fontSize={'md'}>Handling all the possible states</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>Marketing webpage and in-app information screens</Heading>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_13.webp' alt="Marketing webpage" />
                        <Text fontSize={'md'}>Marketing webpage</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_14.webp' alt="In-app info screen" />
                        <Text fontSize={'md'}>In-app info screen</Text>
                    </Center>

                    {/* <Center as={Stack}>
                        <Image src='/projects/audit/audit_6.webp' alt="Handling various states" />
                        <Text fontSize={'md'}>Handling various states</Text>
                    </Center> */}

                    {/* <iframe
                        title="Handling various states"
                        // style="border: 1px solid rgba(0, 0, 0, 0.1);"
                        width="800" height="450"
                        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0Ku53NwnyO3dl7bGMphk3S%2FPortfolio-Audit%3Fpage-id%3D2765%253A18718%26type%3Ddesign%26node-id%3D12231-21215%26viewport%3D852%252C515%252C0.09%26t%3DZuWPgyYve4j7Ljgo-1%26scaling%3Dscale-down-width%26mode%3Ddesign"
                        allowfullscreen /> */}

                    <Box h={5}></Box>
                    <Center>
                        <Heading size='sm'>THE END</Heading>
                    </Center>

                    <Box h={10}></Box>

                </Stack>
            </Container>
        </>
    );
}
export default Audit;