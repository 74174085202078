import {
    Container,
    Stack,
    Box,
    HStack,
    Text,
    Divider,
    Flex,
    SimpleGrid,
    useColorModeValue,
} from '@chakra-ui/react';
import ProjectCard from './ProjectCard';

const Projects = ({ color }) => {
    return (
        <>
            <Container maxW={"4xl"} id="projects">
                <Stack
                    as={Box}
                    textAlign={"center"}
                    spacing={{ base: 8, md: 14 }}
                    pb={{ base: 20, md: 36 }}
                >
                    <Stack align="left" direction="column" p={0}>
                        <HStack mx={4}>
                            <Text color={`${color}.400`} fontWeight={900}>
                                02
                            </Text>
                            <Text fontWeight={900} textTransform={'uppercase'}>Projects</Text>
                        </HStack>
                        <Divider orientation="horizontal" />
                    </Stack>

                    <Flex mx={['0', '0', '10']} bg={useColorModeValue('white', 'gray.800')}
                        color={useColorModeValue('#3D4958', 'white')}>
                        <SimpleGrid mx='auto' columns={['1', '2', '3']}>
                            {WorkList.map((workItem) => (

                                <ProjectCard key={workItem.title} item={workItem} color={color} />

                            ))}
                        </SimpleGrid>
                    </Flex >
                </Stack>
            </Container>
        </>
    )
}
export default Projects;

const WorkList = [
    {
        imgurl: '/projects/progression.png',
        title: 'Career Progression Framework',
        desc: 'Defining a career map for designers',
        tag: ['Design Leadership'],
        href: 'work/progression',
        alt: ''
    },
    {
        imgurl: '/projects/feedback/feedback_2.jpg',
        title: 'Feedback Guidelines',
        desc: 'Methods and processes for effective feedback',
        tag: ['Design Leadership'],
        href: 'work/feedback',
        alt: ''
    },
    {
        imgurl: '/projects/ketwich/ketwich_1.webp',
        title: 'Ketwich',
        desc: 'Design standardisation for Scripbox',
        tag: ['Design System'],
        href: 'work/ketwich',
        alt: ''
    },
    {
        imgurl: '',
        title: 'Find AR',
        desc: 'Find interesting events and places around you',
        tag: ['0 - 1', 'Research', 'Design'],
        href: '#',
        alt: ''
    },
    {
        imgurl: '/projects/audit/audit_12.webp',
        title: 'Mutual Fund Audit',
        desc: 'A second opinion for your mutual fund investments',
        tag: ['Research', 'Design'],
        href: 'work/audit',
        alt: ''
    },
    {
        imgurl: '/projects/hood/gate_4.webp',
        title: 'NoBrokerHood',
        desc: 'Visitor and Community Management System',
        tag: ['0 - 1', 'Research', 'Design'],
        href: 'work/hood',
        alt: ''
    },
    {
        imgurl: '/projects/spotify/spotify1.webp',
        title: 'What Makes a Song Likeable?',
        desc: 'Analyzing Spotify’s top tracks using Dataviz',
        tag: ['Data Visualization'],
        href: 'work/spotify',
        alt: ''
    },

    {
        imgurl: '/projects/thesis/web_design.jpg',
        title: 'Tangible Grid',
        desc: 'Web design tool for blind users',
        tag: ['Tangible UI'],
        href: 'work/tangible',
        alt: ''
    },

];