import {
    Box,
    Heading,
    Text,
    Stack,
    Center,
    Image,
    useColorModeValue,
    UnorderedList,
    OrderedList,
    ListItem,
    Container
} from "@chakra-ui/react";

const Hood = ({ color }) => {
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <>
            <Container maxW='3xl' pt='124px'>
                {/* <Flex pt='124px'> */}
                <Stack maxW={['sm', 'md', '2xl']} mx='auto' spacing={5} color={textColor} fontSize={'lg'}>
                    <Heading fontWeight={'400'} textAlign={'center'} size='lg'>
                        NoBrokerHOOD
                    </Heading>

                    <Text textAlign={'center'}>
                        Visitor and Community Management System
                    </Text>

                    <Box h={5}></Box>

                    <Center>
                        <Image src='/projects/hood/gate_4.webp' />
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>Project details </Heading>

                    <Text>
                        NoBrokerHOOD is a major project that I worked on during my time at the real-estate startup
                        <Text as={'span'} color={`${color}.400`}> NoBroker</Text> as a Senior UX Designer. It is a
                        novel end-to-end community management system aimed at apartments and gated communities that
                        helps keep track of security, visitor management, staff management, maintenance, and much more.

                    </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Role </Heading>

                    <Text>
                        I was part of a small team consisting of 2 UX Designers and 1 Visual Designer and we worked
                        closely with the Director of Product Management and the CTO. The visual language was largely
                        defined by the Visual Designer, <a href="https://www.linkedin.com/in/gouse/">Mohammed Gouse</a>.
                        In the following sections, I will first give an overview of the overall system followed by a
                        description of the modules and features that I worked on.  </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Challenge </Heading>

                    <Text>In large apartments and gated communities, despite employing security guards, keeping track of
                        all the people coming in and going out is a logistical nightmare leading to security lapses. We
                        noticed that such inadequate management of visitor entries create a lot of problems for
                        residents, such as unauthorized visitor entries and vehicle parking, and maintaining service
                        staff attendance. Another problematic area we identified was when security guards had to get
                        approval via intercom calls from residents to allow their guests in, which could be a hassle
                        when they're busy with other tasks.  </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Solution </Heading>

                    <Text>Create a product to make life more convenient and secure for residents in any housing society.
                        Enable apartments, residential buildings, housing societies, and gated communities to manage
                        various activities such as security management, monitoring visitor entry, automating staff and
                        domestic help entry, finding domestic help(maids, cooks, drivers), and pre-authorising guest
                        visits— all through the use of an app. </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Research </Heading>

                    <Text>Our product and design team interviewed several apartment residents and society managers to find
                        out what are the most pressing issues and needs they currently have. We also interviewed
                        security guards to find out about the needs and issues from their perspective. Following this we
                        visited guards at societies where other competing solutions were being used, and observed their
                        usage. </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>System Overview </Heading>

                    <Text>NoBrokerHOOD consists of 3 major modules/apps: </Text>
                    <Center>
                        <Image src='/projects/hood/gate_3.webp' />
                    </Center>

                    <Box h={5}></Box>

                    <Text>These three apps work together in synergy. Security guards use the Guard app for visitor tracking
                        and getting resident approvals, residents use the Resident app to approve requests, track staff
                        attendance, raise an SOS, find domestic help and much more. The Management Dashboard is used by
                        apartment managers who can approve staff, create/update entries for staff and residents, and
                        send notices. </Text>

                    <Text>The Resident apps (for the residents of the hosuing complex) are on both Android and iOS, the
                        Guard App (for the security guards) is Android-only, and the Management Dashboard is a web app.
                        I was responsible for the design of the Guard app, Management Dashboard and some features of the
                        Resident App. </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Guard App </Heading>

                    <Box h={1}></Box>

                    <Heading size='sm'>REQUIREMENTS </Heading>
                    <Text>This app would installed on an Android device (provided by us) which would be with the security
                        guard(s) at the gate. The main requirements for the Guard App were as follows: </Text>
                    <UnorderedList>
                        <ListItem>
                            The primary job of the security guards at the gate is to check-in the people coming in and
                            checking them out when they leave. Provide a seamless way to do so.
                        </ListItem>
                        <ListItem>
                            Should account for the various type of visitors— delivery executives, pre-invited guests,
                            and also pre-approved, staff such as maids, cooks, and drivers.
                        </ListItem>
                        <ListItem>
                            It is crucial that it should be easy to navigate and understand since the target audience
                            might not be very tech savvy. We also included an added benefit that our Sales executives
                            would train the security guards when the product is being deployed at a client site.
                        </ListItem>
                        <ListItem>
                            Design should account for multi-language support since most security guards might not be
                            well versed with English and our app would be deployed in cities across India with different
                            primary languages.
                        </ListItem>
                    </UnorderedList>

                    <Box h={5}></Box>

                    <Heading size='sm'>DESIGN </Heading>

                    <Text>The primary activities of the guard are: </Text>
                    <OrderedList>
                        <ListItem>
                            Check-in visitor(all kinds): Entry tab
                        </ListItem>
                        <ListItem>
                            Ask for resident approval to let a <i>guest</i> in: Waiting tab
                        </ListItem>
                        <ListItem>
                            Check-out visitor: Exit tab
                        </ListItem>
                    </OrderedList>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/hood/gate_4.webp' />

                        <Text fontSize={'md'}>Guard app home screen/check-in</Text>
                    </Center>
                    <Box h={5}></Box>

                    <h5><b>Check-In</b></h5>

                    <Text>The different kinds of visitors are handled in different ways in the app: </Text>
                    <OrderedList>
                        <ListItem>
                            <b>Frequent and regular visitors</b> like house help, nannies, drivers, cooks, etc. and
                            staff on the apartment payroll have to be registered in the system the first time.
                            <Box h={5}></Box>
                            <UnorderedList>
                                <ListItem>
                                    This can be done on the guard app in the "Add New Service" section and they would
                                    then get added to the system.
                                </ListItem>
                                <ListItem>
                                    We also offered an option that could be enabled in the Management Dashbaord, where
                                    after addition by a guard, the visitor still has to get approval from a separate
                                    authority such as a building. In this case, after addition, the visitor would have
                                    to be approved by the manager through the dashboard (based on id proof).
                                </ListItem>
                                <ListItem>
                                    After this registration (and approval), a unique code will be issued to this visitor
                                    that they need to use to check-in at the gate everytime.
                                </ListItem>
                                <ListItem>
                                    An offered enhancement would be biometric intergration, so that the check-in can be
                                    done using a <b>biometric</b> sensor.
                                </ListItem>
                            </UnorderedList>
                        </ListItem>

                        <Box h={10}></Box>
                        <Center as={Stack}>
                            <Image src='/projects/hood/gate_9.webp' />

                            <Text fontSize={'md'} >Add new service (biometrics)</Text>
                        </Center>

                        <Box h={10}></Box>

                        <Center as={Stack}>
                            <Image src='/projects/hood/gate_14.webp' />

                            <Text fontSize={'md'}  >Biometric Check-in</Text>
                        </Center>
                        <Box h={10}></Box>
                        <Center as={Stack}>
                            <Image src='/projects/hood/gate_12.webp' />
                            <Text fontSize={'md'}  >Staff check-in via passcode</Text>
                        </Center>
                        <Box h={10}></Box>

                        <ListItem>

                            <b>One time visitors</b> like delivery executives, or cabs have to be checked in by entering
                            their phone number followed by other details during the first visit. If the visitor has come
                            before then details will auto-populate once they enter their phone number. Can be
                            pre-approved by residents. Residents can also tell the guards in-app to collect the parcel
                            on their behalf.

                        </ListItem>

                        <Box h={10}></Box>

                        <Center as={Stack}>
                            <Image src='/projects/hood/gate_10.webp' />
                            <Text fontSize={'md'}  >Delivery check-in</Text>
                        </Center>

                        <Box h={10}></Box>

                        <Center as={Stack}>
                            <Image src='/projects/hood/gate_13.webp' />

                            <Text fontSize={'md'}>Cabs check-in</Text>
                        </Center>

                        <Box h={10}></Box>



                        <ListItem>
                            <b>Visiting Guests</b> can be invited in advance by residents using their phone, in which
                            case they will be sent a check-in code that can be used to check-in at the gate. If not,
                            security guards will taake down the details of the guest and ask for resident approval
                            before letting them in.
                        </ListItem>
                        <Box h={10}></Box>

                        <Center as={Stack}>
                            <Image src='/projects/hood/gate_11.webp' />

                            <Text fontSize={'md'}>Guest check-in</Text>
                        </Center>
                        <Box h={10}></Box>
                    </OrderedList>




                    <h5><b>Waiting for approval</b></h5>

                    <Text>After entering the details, if the visitor is not pre-approved, then security guards need to wait
                        for resident approval before letting the visitor enter. The resident can choose to approve or
                        deny the visitor. If the approval request times out, then security guards can manually contact
                        the resident (via phone or intercom) to ask for approval. </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/hood/gate_6.webp' />

                        <Text fontSize={'md'}  >Waiting for approval</Text>
                    </Center>


                    <Box h={5}></Box>

                    <h5><b>Check-out</b></h5>

                    <Text>When a visitor/staff leaves, the security guard needs to check them out on the Exit screen.  </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image maxW={'xs'} src='/projects/hood/gate_15.webp' />

                        <Text fontSize={'md'} >Exit screen</Text>
                    </Center>




                    <Heading size='md'>Management Dashboard </Heading>

                    The Management Dashboard is used by an administrator or manager to maintain the apartment's data.
                    The following operations are conducted in the dashboard:



                    <UnorderedList>
                        <ListItem>
                            Staff approvals
                        </ListItem>

                        <ListItem>
                            Manage Noticeboard — Send notices to residents.
                        </ListItem>

                        <ListItem>
                            Manage flats — Add, update and delete occupancy status, intercom/phone, resident details,
                            assigned parking.
                        </ListItem>

                        <ListItem>
                            Manage Staff — Add, update and delete details of staff employed by the society (such as
                            security guard, gardener, etc.)
                        </ListItem>

                        <ListItem>
                            Manage services — Add, update and delete details of people hired by residents (house help,
                            driver, nanny, etc.) and other regular utility visitors (milkman, newspaper vendor, etc.).
                        </ListItem>

                        <ListItem>
                            View and audit visitor records.
                        </ListItem>

                        <ListItem>
                            Track staff attendance
                        </ListItem>
                    </UnorderedList>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/hood/gate_5.webp' />

                        <Text fontSize={'md'} >Dashboard — Data view and Add view</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>Resident app (Selected feature) </Heading>

                    <Heading size='sm'>Invite guest </Heading>
                    This feature on the Resident app allows the resident to invite and pre-approve guests so that, when
                    they arrive, they can just enter their check-in code (sent via SMS) and enter. The guests will not
                    have to fill in any additional information or wait for approval before entry.
                    <Center as={Stack}>
                        <Image src='/projects/hood/gate_7.webp' />

                        <Text fontSize={'md'} >Invite guest flow</Text>
                    </Center>
                    <Center as={Stack}>
                        <Image src='/projects/hood/gate_16.webp' />

                        <Text fontSize={'md'} >Invite guest</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Center>
                        <Heading size='sm'  >THE END </Heading>
                    </Center>

                    <Box h={10}></Box>
                </Stack>
                {/* </Flex> */}
            </Container>
        </>
    );
}
export default Hood;