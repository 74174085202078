import {
    Stack,
    Heading,
    Text,
    Box,
    Image,
    Center,
    useColorModeValue,
    UnorderedList,
    ListItem,
    OrderedList,
    Container
} from '@chakra-ui/react'

const Progression = ({ color }) => {
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <>
            <Container maxW={'3xl'} pt='124px'>
                {/* <Flex pt='124px'> */}
                <Stack maxW={['sm', 'md', '2xl']} mx='auto' spacing={5} color={textColor} fontSize={'lg'}>
                    <Heading fontWeight={'400'} textAlign={'center'} size='lg'>Progression Framework for Designers</Heading>

                    {/* <Heading fontWeight={'500'} textAlign={'center'} size='sm'>Defining roles and responsibilities for various levels</Heading> */}
                    <Text textAlign={'center'} >Defining roles and responsibilities for various levels</Text>

                    <Box h={5}></Box>

                    <Center>
                        <Image src='/projects/progression.png' />
                    </Center>

                    <Box h={5}></Box>
                    <Box h={5}></Box>

                    <Heading size='md'>The problem</Heading >

                    <Text>When I started managing the design team at <Text as={'span'} color={`${color}.400`}>Scripbox</Text>, I realised there was no formalised
                        definition of the various levels. There was a document they had been using as a reference but it
                        was generic and not really suitable for the Scripbox design team, both in terms of team size as
                        well as the kind of work we do.</Text>

                    <Box h={5}></Box>

                    <Heading size='md'>What we needed</Heading >

                    <UnorderedList>
                        <ListItem>Consistent approach for Scripbox management to track the performance and growth of designers</ListItem>

                        <ListItem>Hiring clarity: What level to hire people into?</ListItem>
                        <ListItem> Give all designers clarity on their roles, what is expected of them, how this is determined
                            and tracked, as well as their future progression.</ListItem>
                    </UnorderedList>

                    <Box h={5}></Box>

                    <Heading size='md'>Competence Criteria</Heading >

                    <Text>I set about researching various career progression frameworks, some that were specially meant for
                        design teams while others were for product or tech teams. I zeroed in on 3 key metrics to track
                        and measure:</Text>

                    <OrderedList>
                        <ListItem><Text as={'span'} color={`${color}.400`}>Knowledge:</Text> Breadth and depth of knowledge and how their area(s) of expertise develop
                            over time.</ListItem>
                        <ListItem><Text as={'span'} color={`${color}.400`}>Delivery:</Text> What they're expected to deliver on and how they go about doing it.</ListItem>
                        <ListItem><Text as={'span'} color={`${color}.400`}>Communication & Leadership:</Text> How they communicate and lead projects and/or people
                            across the business.</ListItem>
                    </OrderedList>

                    <Box h={5}></Box>
                    <Box h={5}></Box>

                    {/* <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450"
                        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FERorCZZu6hrLODQxRxPq6X%2FProgression-Framework%3Fpage-id%3D0%253A1%26node-id%3D3%253A5%26viewport%3D241%252C48%252C0.1%26scaling%3Dcontain%26starting-point-node-id%3D3%253A5"
                        allowfullscreen></iframe> */}

                    {/* <iframe title='Progression Framework slide deck'
                        // style="border: 1px solid rgba(0, 0, 0, 0.1);" 
                        width="800" height="450"
                        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FERorCZZu6hrLODQxRxPq6X%2FProgression-Framework%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D14-16%26viewport%3D482%252C129%252C0.1%26t%3DMBgefLqQj48vBkQe-1%26scaling%3Dcontain%26starting-point-node-id%3D3%253A5%26mode%3Ddesign"
                        allowfullscreen /> */}
                    <Center>
                        <iframe
                            // style="border: 1px solid rgba(0, 0, 0, 0.1);"
                            title='Progression Framework slide deck'
                            width="750"
                            height="450"
                            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FERorCZZu6hrLODQxRxPq6X%2FProgression-Framework%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D3-5%26viewport%3D482%252C129%252C0.1%26t%3DMBgefLqQj48vBkQe-1%26scaling%3Dcontain%26starting-point-node-id%3D3%253A5%26mode%3Ddesign"
                            allowFullScreen
                        />
                    </Center>
                    <Box h={5}></Box>
                    <Center>
                        <Heading size='sm'>THE END</Heading>
                    </Center>
                    <Box h={10}></Box>
                </Stack>
                {/* </Flex> */}
            </Container>
        </>
    );
}
export default Progression;