import {
    Box,
    Heading,
    Container,
    Text,
    Stack,
    Icon,
    Link,
    useColorModeValue,
} from '@chakra-ui/react';
import { NBLogo, SBLogo, infeedoLogo, zetaLogo } from "./svg/SvgIcons";

const Hero = ({ color }) => {
    const logoColors = useColorModeValue('gray.400', 'gray.600');

    return (
        <>
            {/* <Head>
                <link
                    href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
                    rel="stylesheet"
                />
            </Head> */}

            <Container maxW={'4xl'} id='hero'>
                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{ base: 4, md: 8 }}
                    pb={[16, 16, 30]}
                    pt={[32, 32, 48]}
                >
                    <Heading
                        fontWeight={300}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                    // lineHeight={'100%'}
                    >
                        Hi, I'm <Text as={'span'} color={`${color}.400`}>Ashrith Shetty</Text>

                    </Heading>
                    <Text
                        // fontSize={28}
                        fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
                        fontWeight={300}
                        textTransform={'uppercase'}
                        color={useColorModeValue('gray.600', 'gray.300')}

                    // textShadow={'4px 4px #00000022'}
                    >

                        Design Leader | Mentor | Problem Solver

                    </Text>
                    <Stack mt={[8, 8, 10]}>
                        <Text
                            // fontSize={28}
                            fontSize={'lg'}
                            fontWeight={300}
                            color={logoColors}
                        >
                            Previously at
                        </Text>
                        <Stack
                            direction={['row', 'row']}
                            // direction={'row'}
                            spacing={[4, 4, 16]}
                            align={'center'}
                            alignSelf={'center'}
                            position={'relative'}
                            my={[-4, -4, -10]}>
                            <Icon
                                as={infeedoLogo}
                                // boxSize={'100'}
                                boxSize={['65', '75', '116']}
                                fill={logoColors}
                            />
                            <Icon
                                as={SBLogo}
                                // boxSize={'116'}
                                boxSize={['69', '75', '116']}
                                fill={logoColors}
                            />
                            <Icon
                                as={NBLogo}
                                // boxSize={'145'}
                                boxSize={['69', '75', '145']}
                                fill={logoColors}
                            />
                            <Icon
                                as={zetaLogo}
                                boxSize={['39', '50', '79']}
                                fill={logoColors}
                            />
                        </Stack>
                    </Stack>
                    <Text mt={[4, 6, 6]} mb={6} color={useColorModeValue('gray.700', 'gray.200')} fontSize={'lg'}>Reach out to me via <Link href="https://www.linkedin.com/in/ashrith/" color={`${color}.400`}>Linkedin</Link> or <Link
                        href="mailto:ashrith.sh@gmail.com" color={`${color}.400`}>email</Link>.
                    </Text >
                </Stack>
            </Container >

        </>
    );
}

export default Hero;

