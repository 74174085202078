import Nav from './components/Navbar';
import { Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import Hood from './screens/Hood';
import Footer from './components/Footer';
import Progression from './screens/Progression';
import Tangible from './screens/Tangible';
import Spotify from './screens/Spotify';
import Feedback from './screens/Feedback';
import ScrollToTop from './components/ScrollToTop';
import Audit from './screens/Audit';
import Ketwich from './screens/Ketwich';
import Find from './screens/Find';

function App() {
  // Available Colours:
  // blue, cyan, gray, green, orange, pink, purple, red, teal, yellow

  const color = 'blue';

  return (
    <>

      <Nav color={color} />
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<HomeScreen color={color} />} />
          <Route path='/work/hood' element={<Hood color={color} />} />
          <Route path='/work/progression' element={<Progression color={color} />} />
          <Route path='/work/tangible' element={<Tangible color={color} />} />
          <Route path='/work/spotify' element={<Spotify color={color} />} />
          <Route path='/work/feedback' element={<Feedback color={color} />} />
          <Route path='/work/audit' element={<Audit color={color} />} />
          <Route path='/work/ketwich' element={<Ketwich color={color} />} />
          <Route path='/work/find' element={<Find color={color} />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </>
  );
}

export default App;
