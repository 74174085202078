import {
    Box,
    Heading,
    Text,
    Stack,
    Center,
    Image,
    useColorModeValue,
    UnorderedList,
    ListItem,
    Container,
    Card,
    CardHeader,
    Link
} from "@chakra-ui/react";

const Ketwich = ({ color }) => {
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <>
            <Container maxW='3xl' pt='124px'>
                {/* <Flex pt='124px'> */}
                <Stack maxW={['sm', 'md', '2xl']} mx='auto' spacing={5} color={textColor} fontSize={'lg'}>
                    <Heading fontWeight={'400'} textAlign={'center'} size='lg'>
                        Ketwich Design System
                    </Heading>

                    <Text textAlign={'center'}>
                        Design standardisation for Scripbox
                    </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/ketwich/ketwich_3.webp' />
                        {/* <Text fontSize={'md'}>Photo by <a href="https://unsplash.com/@balazsketyi?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Balázs Kétyi</a> on <a href="https://unsplash.com/photos/silver-imac-9VzoRKfBsMM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                        </Text> */}
                    </Center>

                    <Box h={5}></Box>

                    <Card
                        maxW='3xl'
                        bg={useColorModeValue('gray.100', 'gray.700')}
                        //#1a202c'
                        // filter='auto'
                        // blur={'1px'}
                        variant='elevated'>

                        <CardHeader>
                            <Stack direction='row' align={['top', 'center']}>
                                <Image src='/projects/ketwich/wand.webp' width={'30px'} h='30px' />

                                <Box textAlign={'left'}>

                                    <Link color={`${color}.400`} href='https://www.figma.com/file/iHDygjpupkgB6Pycl9OJBD/Ketwich-Design-system?node-id=3%3A35&t=uuukvS85IxXKa4kT-1' ><b>Ketwich Design System</b></Link>

                                </Box>
                            </Stack>
                        </CardHeader>

                    </Card >

                    <Box h={5}></Box>

                    <Heading size='md'>Project details </Heading>

                    <Text>
                        The Scripbox web and mobile apps were plagued with numerous inconsistencies and mismatching design elements. Different product pods were using different versions of UI components leading to a non-uniform experience across the product. By developing Ketwich, we hoped to solve all of those problems and provide users with a consistent and elevated experience.
                    </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Atomic Design</Heading>

                    <Text>
                        We chose to follow the methodology proposed by Brad Frost in his book <Link color={`${color}.400`} href='http://atomicdesign.bradfrost.com/' ><b>Atomic Design</b></Link>.
                    </Text>

                    <Text>
                        Atomic Design groups all components into the following:

                        <Box h={5}></Box>

                        <UnorderedList>
                            <ListItem>Tokens</ListItem>
                            <ListItem>Atoms</ListItem>
                            <ListItem>Molecules</ListItem>
                            <ListItem>Organisms</ListItem>
                            <ListItem>Templates</ListItem>
                            <ListItem>Pages</ListItem>
                        </UnorderedList>

                    </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/ketwich/ketwich_4.webp' />
                        <Text fontSize={'md'}>Source: <Link color={`${color}.400`} href='https://bradfrost.com/blog/post/extending-atomic-design/' ><b>Extending Atomic Design</b></Link></Text>
                    </Center>

                    <Box h={5}></Box>

                    <Text>
                        <b>Design tokens</b> are an agnostic way to store variables such as typography, color, and spacing so that your design system can be shared across platforms like iOS, Android, and regular websites.
                    </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/ketwich/ketwich_7.webp' />
                        <Text fontSize={'md'}>Standardising spacing</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/ketwich/ketwich_8.webp' />
                        <Text fontSize={'md'}>Defining grids</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/ketwich/ketwich_9.webp' />
                        <Text fontSize={'md'}>Icon repository</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Text>
                        <b>Atoms</b> of our interfaces serve as the foundational building blocks that comprise all our user interfaces. These atoms include basic HTML elements like form labels, inputs, buttons, and others that can’t be broken down any further without ceasing to be functional.
                    </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/ketwich/ketwich_5.webp' />
                        <Text fontSize={'md'}>Form elements as Atoms</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Text>
                        <b>Molecules</b> are relatively simple groups of UI elements functioning together as a unit. For example, a form label, search input, and button can join together to create a search form molecule.
                    </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/ketwich/ketwich_6.webp' />
                        <Text fontSize={'md'}>Search as a Molecule</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Text>
                        <b>Organisms</b> are relatively complex UI components composed of groups of molecules and/or atoms and/or other organisms. These organisms form distinct sections of an interface, for instance, the header of a webpage which could contain a logo image, search bar, and links.
                    </Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/ketwich/header.webp' />
                        <Text fontSize={'md'}>Header can either be a Molecule or an Organism depending upon the complexity of its subcomponents</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Text>
                        <b>Templates</b> display all the necessary page components functioning together, which provides context for these relatively abstract molecules and organisms. When crafting an effective design system, it’s critical to demonstrate how components look and function together in the context of a layout to prove the parts add up to a well-functioning whole.
                    </Text>

                    {/* <Box h={5}></Box> */}

                    {/* <Center as={Stack}>
                        <Image src='/projects/ketwich/template.gif' />
                        <Text fontSize={'md'}>Templates</Text>
                    </Center> */}

                    {/* <Box h={5}></Box> */}

                    <Text>
                        <b>Pages</b> are specific instances of templates that show what the final UI looks like with real representative content in place.
                    </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Collaboration with Developers </Heading>

                    <Text>
                        We reached out to our Front-end team and created a plan together in order to create a library of reusable components and patterns. This would be maintained not  just on Figma in a master file, but also on Storybook as an accessible library for every Front-end developer.
                    </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Impact</Heading>

                    <Text>
                        Since conception, over 60+ new components were introduced. Existing components and patterns were standardised. Redundant work was significantly reduced as the design system documentation enncouraged designers and developers to reuse components and patterns. The handoff process also became significantly smoother since developers and designers were referring to connsistent and updated libraries. Overall speed of design and front-end execution was noticed to have improved by 40%.
                    </Text>

                    <Box h={5}></Box>

                    <Center>
                        <Heading size='sm'  >THE END </Heading>
                    </Center>

                    <Box h={10}></Box>

                </Stack>
            </Container>
        </>
    );
}
export default Ketwich;