import {
    Flex,
    Button,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    useColorModeValue,
    Stack,
    useColorMode,
    IconButton,
    useMediaQuery,
    useDisclosure,
    Icon,
    Link,
} from "@chakra-ui/react";
import {
    FaBars as HamburgerIcon,
    FaMoon as MoonIcon,
    FaSun as SunIcon,
} from 'react-icons/fa6';
import { useState } from "react";
import { Logo } from "./svg/SvgIcons";
import { useLocation, useNavigate } from "react-router-dom";

const scrollWithOffset = (selector, offset) => {
    window.scrollTo({
        behavior: 'smooth',
        top: document.querySelector(selector).getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
    })
}

const Nav = ({ color }) => {
    // const profile = ProfileArray();
    // const colors = {
    //     "blue": "#3182CE",
    //     "cyan": "#00B5D8",
    //     "gray": "#718096",
    //     "green": "#38A169",
    //     "orange": "#DD6B20",
    //     "pink": "#D53F8C",
    //     "purple": "#805AD5",
    //     "red": "#E53E3E",
    //     "teal": "#319795",
    //     "yellow": "#D69E2E"
    // };
    const [scroll, setScroll] = useState(false);
    const { colorMode, toggleColorMode } = useColorMode();
    const { isOpen, onOpen, onClose } = useDisclosure();
    // const [scrollState, setScrollState] = useState(null);

    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();

    const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
    const scrollToHero = () => {
        // const heroSection = document.querySelector("#hero");
        // heroSection.scrollIntoView({ behavior: "smooth" });
        if (path === '/')
            scrollWithOffset('#hero', 0);
        else {
            navigate('/', { state: '#hero' });
        }
    };
    const scrollToAbout = () => {
        // const aboutSection = document.querySelector("#about");
        // aboutSection.scrollIntoView({ behavior: "smooth" });
        if (path === '/')
            scrollWithOffset('#about', 100);
        else {
            navigate('/', { state: '#about' });
        }

    };
    const scrollToExperience = () => {
        // const experienceSection = document.querySelector("#career");
        // experienceSection.scrollIntoView({ behavior: "smooth" });
        // scrollWithOffset('#career', 100);
        if (path === '/')
            scrollWithOffset('#career', 100);
        else {
            navigate('/', { state: '#career' });
        }
    };
    const scrollToProjects = () => {
        // const projectsSection = document.querySelector("#projects");
        // projectsSection.scrollIntoView({ behavior: "smooth" });
        // scrollWithOffset('#projects', 100);
        if (path === '/')
            scrollWithOffset('#projects', 100);
        else {
            navigate('/', { state: '#projects' });
        }
    };
    // const scrollToContact = () => {
    //     // const contactSection = document.querySelector("#contact");
    //     // contactSection.scrollIntoView({ behavior: "smooth" });
    //     scrollWithOffset('#contact', 100);
    // };
    const changeScroll = () =>
        document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
            ? setScroll(true)
            : setScroll(false);

    window.addEventListener("scroll", changeScroll);

    // const TbLetterComponents = [];

    // for (let i = 0; i < profile.logo.length; i++) {
    //     const letter = profile.logo[i];
    //     const component = TbIcons[`TbLetter${letter}`];
    //     TbLetterComponents.push(component);
    // }

    return (
        <>
            <Flex
                bg={useColorModeValue("gray.200", "gray.900")}

                px={4}
                h={16}
                boxShadow={scroll ? "base" : "none"}
                zIndex="sticky"
                position="fixed"
                as="header"
                alignItems={"center"}
                justifyContent={"space-between"}
                w="100%"
            >
                <Link onClick={scrollToHero}>
                    {/* <HStack>
                        {TbLetterComponents.map((Component, index) => (
                            <Component key={index} color={colors[color]} />
                        ))}
                    </HStack> */}
                    {/* <Image
                        // textAlign={useBreakpointValue({ base: 'center', md: 'start' })}
                        src='logo.svg'
                        fill={'white'} /> */}
                    <Icon
                        as={Logo}
                        // color={useColorModeValue('gray.800', 'gray.300')}
                        boxSize={'116'}
                        fill={useColorModeValue(`${color}.400`, 'gray.300')}
                    />
                </Link>

                <Flex alignItems={"center"}>
                    <Stack direction={"row"} spacing={7}>
                        {isLargerThanMD ? (
                            <>
                                <Button
                                    // colorScheme={color}
                                    variant="ghost"
                                    onClick={scrollToAbout}>
                                    About me
                                </Button>
                                <Button
                                    // colorScheme={color}
                                    variant="ghost"
                                    onClick={scrollToProjects}>
                                    Projects
                                </Button>
                                <Button
                                    // colorScheme={color}
                                    variant="ghost"
                                    onClick={scrollToExperience}>
                                    Career
                                </Button>

                                {/* <Button
                                    // colorScheme={color}
                                    variant="ghost"
                                    onClick={scrollToContact}>
                                    Contact
                                </Button> */}
                            </>
                        ) : (
                            <></>
                        )}
                        <Button onClick={toggleColorMode} variant='ghost'>
                            {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                        </Button>

                        {isLargerThanMD ? (
                            <></>
                        ) : (
                            <>
                                <Button
                                    as={IconButton}
                                    icon={<HamburgerIcon />}
                                    onClick={onOpen}
                                ></Button>
                                <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
                                    <DrawerOverlay />
                                    <DrawerContent>
                                        <DrawerBody>
                                            <Button variant="ghost" onClick={scrollToAbout}>
                                                About me
                                            </Button>

                                            <Button variant="ghost" onClick={scrollToProjects}>
                                                Projects
                                            </Button>
                                            <Button variant="ghost" onClick={scrollToExperience}>
                                                Career
                                            </Button>

                                        </DrawerBody>
                                    </DrawerContent>
                                </Drawer>
                            </>
                        )}
                    </Stack>
                </Flex>
            </Flex>
        </>
    );
}

export default Nav;