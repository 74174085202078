import Hero from "../components/Hero";
import About from "../components/About";
import Projects from "../components/Projects";
import Resume from "../components/Resume";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const scrollWithOffset = (selector, offset) => {
    window.scrollTo({
        behavior: 'smooth',
        top: document.querySelector(selector).getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
    })
}

const HomeScreen = ({ color }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state;
    // console.log(state);

    useEffect(() => {
        if (state !== null) {
            scrollWithOffset(state, 100);
            // setScrollState(null);
            navigate(location.pathname, { replace: true });
        }
    },);

    return (
        <>
            <Hero color={color} />
            <About color={color} />
            <Projects color={color} />
            <Resume color={color} />
        </>
    );
}
export default HomeScreen;