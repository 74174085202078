import {
    Container,
    Stack,
    useColorModeValue,
    Image,
    Heading,
    Box,
    Text,
    Center,

} from '@chakra-ui/react';

const Find = ({ color }) => {
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <>
            <Container maxW={"3xl"} pt='124px'>
                <Stack maxW={['sm', 'md', '2xl']} mx='auto' spacing={5} color={textColor} fontSize={'lg'}>
                    <Heading fontWeight={'400'} textAlign={'center'} size='lg'>Find AR</Heading>

                    <Text textAlign={'center'}>Find interesting events and places around you</Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/audit/audit_8.webp' alt="Overview image for feature" />
                        {/* <Text fontSize={'md'}>Hardware prototype of the TUI tool along with the generated output</Text> */}
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>Overview</Heading>

                    <Text>
                        This was a freelance consultancy project I did for a pre-product startup client.
                    </Text>

                    <Text>
                        Their objective was to build a location-aware platform that enables users to create and consume digital artefacts, such as messages and interactive media, tied to specific geographical coordinates. It provides guidance and answers location-specific queries, facilitates spontaneous promotions for local businesses, informs users about nearby services and unique points of interest, and aids travellers in accessing localised, hard-to-find information. Future plans include data provision to the developer community via SDK and APIs for innovative developments in wearable devices and geospatial computing.
                    </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Need for the product</Heading>

                    <Text>
                        Our personal experiences show that access to local user-generated data can provide utility and greater engagement with people and businesses in the locality. Currently, very few services are able to provide real-time and ephemeral data in a contextual manner. We feel that the time is right to introduce this type of a geospatial service because there are macro trends around internet connectivity, consumer behaviour around consumption and generation of user-generated content and wearable devices are potentially creating a huge demand for geospatial data. We intend to be early movers and enablers in this space to capitalise on this future demand.
                    </Text>

                    <Box h={5}></Box>

                    <Heading size='md'>Competitive Analysis</Heading>

                    <Box h={5}></Box>

                    <Heading size='md'>Personas</Heading>

                    <Box h={5}></Box>

                    <Heading size='md'>Differentiation Strategies</Heading>

                    <Box h={5}></Box>

                    <Heading size='md'>Challenges</Heading>

                    <Box h={5}></Box>

                </Stack>
            </Container>
        </>
    );
}
export default Find;