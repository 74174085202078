import {
    Divider,
    Stack,
    Text,
    Container,
    Box,
    HStack,
    Highlight,
    useColorModeValue
} from "@chakra-ui/react";
// import ProfileArray from "./ProfileArray";

const About = ({ color }) => {
    // const profile = ProfileArray();
    return (
        <>
            <Container maxW={"4xl"} id="about">
                <Stack
                    as={Box}
                    textAlign={"center"}
                    spacing={{ base: 8, md: 14 }}
                    pb={{ base: 20, md: 36 }}
                >
                    <Stack align="left" direction="column" px={0}>
                        <HStack ml={4} pr={5}>
                            <Text fontWeight={900} color={`${color}.400`} >
                                01
                            </Text>
                            <Text fontWeight={900} textTransform={'uppercase'}>About me</Text>
                        </HStack>
                        <Divider orientation="horizontal" />
                    </Stack>
                    {/* <Text color={"gray.600"} fontSize={"xl"} px={4}>
                        Fill in about section here
                    </Text> */}
                    <Text color={useColorModeValue('gray.700', 'gray.200')} fontSize={'xl'} textAlign={'left'} px={4} >
                        Musician. Code Tinkerer. Wannabe writer. Bookworm. Backpacker. Autodidact. Philomath.
                    </Text>
                    <Text color={useColorModeValue('gray.600', 'gray.400')} fontSize={'xl'} textAlign={'left'} px={4}>
                        <Highlight query={['Zeta', 'NoBroker', ' University of Maryland, USA', 'Scripbox', 'inFeedo']} styles={{
                            color: `${color}.400`
                        }}>
                            I am curious, innovative, enjoy building things, and thrive on challenges. I started my career as a coder and transitioned into UX Design. I have worked at some of India's most prominent startups, including Zeta and NoBroker. In 2018, I took a mid-career break to level up my skills with an MS in Human-Computer Interaction from the University of Maryland, USA. Most recently, I have built, led, and mentored the design teams at Scripbox and inFeedo.
                        </Highlight>
                    </Text>

                </Stack>
            </Container>
        </>
    );
}

export default About;
