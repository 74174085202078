import {
    Center,
    Box,
    useColorModeValue,
    Image,
    HStack,
    Heading,
    Text,
} from '@chakra-ui/react'
import { Link as LinkRouter } from 'react-router-dom';

const ProjectCard = ({ item, color }) => {
    // const boxShadowColor = useColorModeValue(color, 'black');

    return (
        <>
            {/* <Link
                href={item.href}

            > */}
            < Center pb={4}>

                <Box
                    as={LinkRouter}
                    w="xs"
                    rounded={'md'}
                    my={5}
                    mx={[0, 5]}
                    overflow={'hidden'}
                    bg={useColorModeValue('white', 'gray.700')}
                    // border={'1px'}
                    // borderColor="black"
                    _hover={{
                        textDecoration: 'none',
                        boxShadow: 'dark-lg',
                    }}
                    boxShadow={'lg'}
                    to={item.href}
                    // onClick={item.href==='#'}
                    style={{ pointerEvents: item.href === '#' ? 'none' : '' }}
                >
                    <Box h={'200px'} borderBottom={'1px'} borderColor="black">
                        <Image
                            src={
                                item.imgurl ? item.imgurl : '/projects/audit/frame_184.webp'
                            }
                            roundedTop={'sm'}
                            objectFit="cover"
                            h="full"
                            w="full"
                            alt={item.alt}
                        />
                    </Box>
                    <Box p={4} textAlign={'left'}>
                        <HStack spacing={2}>
                            {item.tag.map((tagItem) => (
                                <Box
                                    bg={`${color}.500`}
                                    display={'inline-block'}
                                    px={2}
                                    py={1}
                                    rounded={'2xl'}
                                    color="white"
                                    mb={2}
                                    key={tagItem}
                                >

                                    <Text fontSize={'13px'} fontWeight="medium">
                                        {tagItem}
                                    </Text>

                                </Box>
                            ))}
                        </HStack>
                        <Heading fontSize={'md'} fontWeight={'600'} noOfLines={1} pt={2}>
                            {item.title}
                        </Heading>
                        <Text noOfLines={2} pt={1}>
                            {item.desc}
                        </Text>
                    </Box>

                </Box >
                {/* </Link> */}
            </Center >
            {/* </Link > */}
        </>
    );
}
export default ProjectCard;