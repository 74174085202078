import {
    Text,
    Heading,
    Box,
    Center,
    Image,
    Stack,
    useColorModeValue,
    Card,
    CardHeader,
    Link,
    Container,
} from '@chakra-ui/react'
// import { FaExternalLinkAlt as ExternalLinkIcon } from 'react-icons/fa'
const Tangible = ({ color }) => {
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <>
            <Container maxW='3xl' pt='124px'>
                {/* <Flex pt='124px'> */}
                <Stack maxW={['sm', 'md', '2xl']} mx='auto' spacing={5} color={textColor} fontSize={'lg'}>

                    <Heading fontWeight={'400'} textAlign={'center'} size='lg'>Tangible Web Layout Design for Blind and Visually Impaired People</Heading>


                    <Text textAlign={'center'}>Using a Tangible User Interface (TUI) for accessible web design</Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/thesis/thesis_model.png' alt="The hardware prototype and output on the browser" />
                        <Text fontSize={'md'}>Hardware prototype of the TUI tool along with the generated output
                        </Text>
                    </Center>

                    <Box h={5}></Box>

                    <Card
                        maxW='3xl'
                        bg={useColorModeValue('gray.100', 'gray.700')}
                        //#1a202c'
                        // filter='auto'
                        // blur={'1px'}
                        variant='elevated'
                    >
                        <CardHeader>
                            <Stack direction='row' align={['top', 'center']}>
                                <Image src='/projects/thesis/paper.png' width={'30px'} h='30px' />

                                <Box textAlign={'left'}>

                                    <Text fontSize={'lg'} ><Link color={`${color}.400`} href='https://dl.acm.org/doi/10.1145/3526113.3545627' ><b>Paper published</b></Link>, UIST Conference '22 (26.3% acceptance rate)</Text>


                                </Box>
                            </Stack>
                        </CardHeader>

                        <CardHeader>
                            <Stack direction='row' align={['top', 'center']}>
                                <Image src='/projects/thesis/paper.png' width={'30px'} h='30px' />

                                <Box textAlign={'left'}>
                                    <Text fontSize={'lg'} ><Link color={`${color}.400`} href='https://dl.acm.org/doi/10.1145/3379350.3416178'><b>Paper published</b></Link>, UIST Conference '20 (58.9% acceptance rate)</Text>

                                </Box>
                            </Stack>
                        </CardHeader>

                        <CardHeader>
                            <Stack direction='row' >
                                <Image src='/projects/thesis/award.png' width={'30px'} h='30px' />

                                <Box textAlign={'left'}>
                                    <Text fontSize={'lg'} ><b>Honorable Mention: Jenny Preece and Ben Shneiderman Award for Excellence</b>, University of Maryland, 2020</Text>
                                    <Text fontSize={'lg'} ></Text>
                                </Box>
                            </Stack>
                        </CardHeader>


                    </Card >
                    <Box h={5}></Box>
                    {/* <div class="box">
                        <Image class="work-logo" src="../images/paper.png" />

                        <div class="work-set">
                            <h4><b>Paper published at conference</b></h4>
                            <h5><b>UIST '22: Proceedings of the 35th Annual ACM Symposium on User Interface Software and
                                Technology</b></h5>
                            <p><a href="https://dl.acm.org/doi/10.1145/3526113.3545627">Paper</a></p>
                            <h5>Acceptance Rate: 25.9% (95 / 367) </h5>
                        </div>

                        <img class="work-logo" src="../images/paper.png">

                            <div class="work-set">
                                <h4><b>Poster accepted at conference</b></h4>
                                <h5><b>UIST '20 Adjunct: Adjunct Publication of the 33rd Annual ACM Symposium on User
                                    Interface Software and Technology</b></h5>
                                <p><a href="https://dl.acm.org/doi/pdf/10.1145/3379350.3416178">Paper</a> | <a
                                    href="https://docs.google.com/viewerng/viewer?url=https://ashrithshetty.com/doc/UIST_Poster_final.pdf">Poster</a>
                                </p>
                                <h5>Acceptance Rate: 58.9% (33 / 56) </h5>
                            </div>

                            <Box h={5}></Box>

                            <img class="work-logo" src="../images/award.png">
                                <div class="work-set">
                                    <h4><b>Honorable Mention</b></h4>
                                    <h5><b>2020 Jenny Preece and Ben Shneiderman Award for Excellence, University of
                                        Maryland</b></h5>
                                </div>

                            </div> */}




                    <Heading size={'md'}>Project details</Heading>

                    <Text>This is an independent research conducted for my MS-HCI thesis. Subsequently, after my graduation, my thesis advisor was able to continue this project with other students</ Text>

                    <Box h={5}></Box>

                    <Heading size={'md'}>Abstract</Heading>

                    < Text>Blind and visually impaired (BVI) people typically consume web content by using screen readers.
                        However, there is a lack of accessible tools that allow them to create the visual layout of a
                        website without assistance. Recent work in this space enables blind people to edit existing
                        visual layout templates, but there is no way for them to create a layout from scratch. This
                        research project aims to implement a 3D printed tangible user interface (TUI) tool, named
                        <b><i>Sparsha</i></b>, for website layout creation that blind users can use without assistance.
                    </ Text>

                    < Text>I conducted a semi-structured interview followed by a co-design session with a blind graduate
                        student who is also an accessibility researcher. Based on the elicited insights and designs, I
                        implemented the final system using 3D printed tactile elements, an Arduino-powered sensing
                        circuit and a Web server that renders the final HTML layout, provides feedback and can be used
                        to populate content.</ Text>

                    <Box h={5}></Box>

                    <Heading size={'md'}>The Problem</Heading>

                    < Text>The on-going democratization of assistive technologies is improving the quality of life for
                        millions of people who identify as blind or visually impaired (BVI). Devices like screen
                        readers, tactile printers, and braille displays have also led to new employment opportunities
                        for BVI people. Recent accessible programming tools make it possible for BVI developers to write
                        code to express logic and algorithms and as a result, the number of blind programmers has been
                        steadily rising in the last few years (based on StackOverflow's Annual Developer Survey).
                        Although BVI developers are now able to write code, they still lack accessible ways to define
                        the visual layouts, for instance, the layout of an HTML webpage. Lacking direct feedback about
                        the size and the position of elements, BVI developers can only write code to specify
                        <em>what</em> content they create, but do not understand <em>where</em> to put them and
                        <em>how</em> they look.</Text>

                    <Box h={5}></Box>

                    <Heading size={'md'}>Approach</Heading>

                    <Text>Ability-based design (Wobbrock et al.) is an approach that advocates shifting the focus of
                        accessible design from disability to ability. Designers should strive to leverage all that their
                        target audience <em>can</em> do. Blind users use their sense of touch to "navigate and negotiate
                        the world" in their daily life. Previous studies have revealed that blind people have higher
                        tactile acuity than sighted people (Cattaneo et al.). Based on this perspective, I see value in
                        a tool that can support this tactile perceptual ability by using a Tangible User Interface
                        (TUI).
                    </Text>

                    <Box h={5}></Box>

                    <Heading size={'md'}>The Process</Heading>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/thesis/thesis_method.png' alt="Design process" />
                        <Text fontSize={'md'}  >The design process</Text>
                    </Center>

                    <Text>Employing a user-centered research method, I started with a semi-structured interview with a
                        blind participant to understand the challenges BVI people face as well as potential solutions
                        that they have explored (if any). Based on the interview, I prepared a list of low-fidelity
                        physical probes and artifacts that could potentially help BVI developers in layout creation.
                        Following the Participatory Design approach, these physical probes were then used in a co-design
                        session with the blind participant. The behavioral evidence collected from the co-design
                        session, as well as the interview with the BVI user guided the design of the initial prototype
                        of Sparsha.</Text>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <Image src='/projects/thesis/thesis_codesign.png' alt="Co-design session with the blind participant" />
                        <Text fontSize={'md'}  >Co-design session with the blind participant.</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Heading size='md'>Sparsha - The TUI Web Design tool</Heading>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <img src='/projects/thesis/thesis_system.png' alt="System diagram for the tangible web design tool" />
                        <Text fontSize={'md'}  >System diagram for the proposed solution.</Text>
                    </Center>

                    <Box h={5}></Box>

                    <Box h={5}></Box>

                    <Center as={Stack}>
                        <img src='/projects/thesis/thesis_model.png' alt="The hardware prototype and output on the browser"
                            id="tui_proto" />
                        <Text fontSize={'md'}  >Hardware prototype of the TUI tool along with the generated output
                        </Text>
                    </Center>

                    <Box h={5}></Box>

                    <Text>When a user places 4 beads of a type on the board (in the shape of a rectangle due to the edge
                        constraints), they snap on to the board due to magnetic contact points. An Arduino-based sensing
                        circuit attached to the base helps identify the type, and location of the beads. It then sends
                        this information over WiFi to the web server which in turn sends an event to the client browser
                        to render an HTML element of the specified type at the specified location. The web application
                        also gives audio feedback to confirm the placement of the correct HTML element at the correct
                        location.</Text>

                    <Box h={5}></Box>

                    <Heading size={'md'}>Published Papers</Heading>

                    {/* <Box h={5}></Box> */}

                    {/* <HStack>
                        <Box w='360px' h='480px'>
                            <iframe
                                title='UIST 2020 Paper'
                                src="https://docs.google.com/gview?url=https://ashrithshetty.com/doc/UIST2020_PosterPaper_final.pdf&embedded=true"
                                // style="width:360px; height:480px;"
                                frameborder="0"
                            />
                        </Box>
                        <Box w='360px' h='480px'>
                            <iframe
                                title='UIST 2022 Paper'
                                src="/doc/TangibleGrid.pdf"
                            // style="width:360px; height:480px;"
                            // frameborder="0"
                            />
                        </Box>
                    </HStack> */}

                    {/* <Stack direction={['column', 'row']}>
                        <object data="/doc/UIST2020_PosterPaper.pdf" type="application/pdf" width="360px" height="480px">
                            
                        </object>
                        <object data="/doc/TangibleGrid.pdf" type="application/pdf" width="360px" height="480px">
                            
                        </object>
                    </Stack> */}

                    <Text><Link color={`${color}.400`} href="/doc/UIST2020_PosterPaper.pdf">UIST Conference '20</Link> | <Link color={`${color}.400`} href="/doc/TangibleGrid.pdf">UIST Conference '22</Link></Text>

                    <Box h={5}></Box>
                    <Center>
                        <Heading size='sm'>THE END</Heading>
                    </Center>
                    <Box h={10}></Box>
                </Stack>
                {/* </Flex > */}
            </Container >
        </>
    );
}
export default Tangible;