import {
    Card,
    CardBody,
    CardHeader,
    Stack,
    Box,
    Text,
    Image,
    useColorModeValue,
    Accordion,
    AccordionButton,

} from "@chakra-ui/react";

const ResumeCard = ({ item }) => {
    return (
        <>
            <Card
                // maxW='3xl'
                bg={useColorModeValue('gray.100', 'gray.700')}
                //#1a202c'
                // filter='auto'
                // blur={'1px'}
                variant='elevated'
            >
                <CardHeader>
                    <Stack direction='row'>
                        <Image src={item.avatar} w={'50px'} h='50px' alt='company logo' />

                        <Box textAlign={'left'}>
                            <Text fontSize={'lg'} fontWeight={800} size='sm' noOfLines={2}>{item.title}</Text>
                            <Text fontSize={'md'} fontWeight={600}>{item.company}</Text>
                        </Box>
                    </Stack>
                </CardHeader>
                {(item.details !== '') &&
                    <CardBody>
                        <Accordion>
                            <AccordionButton>
                                View details
                            </AccordionButton>
                        </Accordion>
                    </CardBody>}
            </Card >
        </>
    );
}
export default ResumeCard;